import React from 'react';
import AppRouter from './components/AppRouter';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Agrega el enlace de la fuente 'Ubuntu' aquí
const googleFontsLink = document.createElement('link');
googleFontsLink.rel = 'stylesheet';
googleFontsLink.href = 'https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap';
document.head.appendChild(googleFontsLink);

const theme = createTheme({
  typography: {
    fontFamily: 'Ubuntu, sans-serif',
  },
  palette: {
    primary: {
      main: '#03318C',
    },
    secondary: {
      main: '#F2527D',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 18
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>

  );
}

export default App;
