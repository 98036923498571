import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Container, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import SearchIcon from '@mui/icons-material/Search';


const Login = () => {

  const [type, setType] = useState("DNI")
  const [document, setDocument] = useState("")
  const [load, setLoad] = useState(false)

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
        backgroundImage: 'url("/img/fondo.jpg")',
        backgroundSize: 'cover',
      }}
    >
        <Card sx={{ maxWidth: 445, borderRadius:9 }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mt:4, fontWeight:400 }} color={'primary'}>
              Grupo <br /> Gran Sur Peruano
            </Typography>
            <img src="/img/logo.png" style={{ width: 220, margin: 'auto', marginBottom: 25, marginTop: 25 }} alt="" />
          </CardContent>
          <CardContent sx={{ textAlign: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Documento</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Documento"
                onChange={handleChange}
              >
                <MenuItem value={"DNI"}>DNI</MenuItem>
                <MenuItem value={"RUC"}>RUC</MenuItem>
              </Select>
            </FormControl>
            <TextField fullWidth id="outlined-basic" type='number' sx={{ mt: 3 }} label="Numero de documento" focused variant="outlined" onChange={(e) => setDocument(e.target.value)} />
            <LoadingButton component={Link} to={`/userinfo/${document}`} loading={load} variant="contained" startIcon={<SearchIcon />} sx={{ mt: 5, mb: 2 }} size="large">
              Buscar
            </LoadingButton >
          </CardContent>
        </Card>

    </div>
  );
};

export default Login;