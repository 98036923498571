import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Button, Card, CardContent, CardHeader, Container, IconButton, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import moment from 'moment';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'fecha',
    headerName: 'Fecha',
    width: 120,
    editable: true,
    renderCell: (params) => {
      const { row } = params
      return (<span>{moment(row.fecha).format('YYYY-MM-DD')}</span>);
    },
  },
  {
    field: 'comprobante',
    headerName: 'Comprobante',
    width: 150,
    flex: 1,
  },
  {
    field: 'serie',
    headerName: 'Serie',
    type: 'number',
    width: 80,
    editable: true,
  },
  {
    field: 'correlativo',
    headerName: 'Correlativo',
    type: 'number',
    width: 100,
    align:'left',
    renderCell: (params) => {
      const { row } = params
      return (<span>{row.correlativo}</span>);
    },
  },
  {
    field: 'monto',
    headerName: 'Monto',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Archivos',
    sortable: false,
    headerAlign:'center',
    align:'center',
    width: 300,
    renderCell: (params) => {
      const { row } = params
      return (
        <>
          <Button variant="contained" color='error'  endIcon={<DownloadIcon />} onClick={() => { window.open('https://server2.d-todoimports.com/api/export/comprobante/' + row['id'], '_blank') }}>PDF</Button>&nbsp;&nbsp;&nbsp;
          {
            row.send == 1 &&
            <>
              <Button variant="outlined" color='success'  endIcon={<DownloadIcon />} onClick={() => { window.open(`https://server2.d-todoimports.com/api/dowloadXml/${row['serie']}-${row['correlativo']}/${row['tipoDocumento_id']}/${row['empresa_id']}`, '_blank') }}>XML</Button>&nbsp;&nbsp;&nbsp;
              <Button variant="outlined"  endIcon={<DownloadIcon />} onClick={() => { window.open(`https://server2.d-todoimports.com/api/dowloadCdr/${row['serie']}-${row['correlativo']}/${row['tipoDocumento_id']}/${row['empresa_id']}`, '_blank') }}>CDR</Button>
            </>
          }

        </>
      );
    },
  },
];

const UserInfo = () => {

  const { documentId } = useParams()
  const [info, setInfo] = useState({
    nombres: '',
    paterno: '',
    materno: '',
  })
  const [apiData, setApiData] = useState([])

  const [load, setLoad] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://server2.d-todoimports.com/api/apiComprobantes/${documentId}`);
        const data = await response.json();
        if (response) {
          setApiData(data.comprobantes);
          setInfo(data.cliente)
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [documentId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
        backgroundImage: 'url("/img/bg.jpg")',
        backgroundSize: 'cover',
      }}
    >
      <Container>
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#0A4DA6" }} aria-label="recipe">
                E
              </Avatar>
            }
            title={info.nombres + ' ' + info.paterno + ' ' + info.materno}
            subheader={documentId}
            action={
              <IconButton aria-label="settings" sx={{mt:1}}>
                <LogoutIcon />
              </IconButton>
            }
          />
        </Card>
        <DataGrid
          rows={apiData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 12,
              },
            },
          }}
          pageSizeOptions={[12]}
          sx={{
            width: '100%',
            minHeight: 500,
            mt:2,
            background:'white'
          }}
        />
      </Container>
    </div>
  );
};

export default UserInfo;